import { useState } from 'react';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import { TextField } from '@mui/material';
import { Box } from '@mui/system';
import { ethers } from 'ethers';

const useBalance = () => {
    const [errorMessage, setErrorMessage] = useState(null);
    const [defaultAcc, setDefaultAcc] = useState(null);
    const [UserBalance, setUserBalance] = useState(null);
    const [connButtonTxt, setConnButtonTxt] = useState('Connect');

    const connWalletHandler = () => {
        if(window.ethereum) {
            window.ethereum.request({method: 'eth_requestAccounts'})
            .then(result => {
                accountChangedHandler(result[0]);
                setConnButtonTxt("Wallet connected");
            })
        } else {
            setErrorMessage("Need to install Metamask");
        }
    }

    const accountChangedHandler = (newAccount) => {
        setDefaultAcc(newAccount)
        getUserBalance(newAccount)
    }

    const getUserBalance = (address) => {
        window.ethereum.request({method: 'eth_getBalance', params: [address, 'latest']})
        .then(balance => {
            setUserBalance(ethers.utils.formatEther(balance))
        })
    }

    return (
        <div>
            <Box sx={{ mx: 70 }}> 
            <Button variant="contained" color="primary" onClick={connWalletHandler}>{connButtonTxt}</Button>
            <br></br><br></br><br></br>
            <TextField
            focused 
          label="Address:   "
          id="outlined-start-adornment"
          sx={{ m: -1, width: '53ch'}}
          InputProps={{
           startAdornment: <InputAdornment position="start">{defaultAcc}</InputAdornment>,
          }}
        />

<br></br><br></br><br></br>
            <TextField
            focused 
          label="Amount:   "
          id="outlined-start-adornment"
          sx={{ m: -1, width: '53ch'}}
          InputProps={{
           startAdornment: <InputAdornment position="start">{UserBalance}</InputAdornment>,
          }}
        />
            {errorMessage}
            </Box>
        </div>
    )
        }

export default useBalance