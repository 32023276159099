import logo from './xawc_logo.png';
import './App.css';
import * as React from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import useBalance from './useBalance';

function App() {


  const balance = useBalance();

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          XAWC FTSO.
        </p>
        <p></p>
      </header>
      <body>
      <h6 className="balance">{balance}</h6>
      </body>
    </div>
  );
}

export default App;
